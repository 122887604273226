import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../scss/all.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from "gatsby"
import { IconContext } from "react-icons";
import CookieConsent from "./CookieConsent";


const Layout = ({ children, title, description }) => {

  // const { title_, description } = useSiteMetadata();

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/ml-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/ml-32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/ml-16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        {/* <meta property="og:image" content={`${withPrefix("/")}img/og-image.jpg`} /> */}
      </Helmet>
      <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
        <Navbar />
        <div>{children}</div>
        <Footer />
        <CookieConsent 
        disableButtonStyles={true}
        onAccept={() => {
          window.createGA();
          var pagePath = window.location ? window.location.pathname + window.location.search + window.location.hash : undefined;
          window.ga("set", "page", pagePath);
          window.ga("send", "pageview");
        }}>
            Esta web usa cookies para mejorar la experiencia de usuario.
        </CookieConsent>
      </IconContext.Provider>
    </div>
  )
}

export default Layout
