import React from 'react'
import { Link } from 'gatsby'


import { MdPhoneAndroid, MdMailOutline, MdLocationOn } from 'react-icons/md'

import { FaFacebookSquare, FaTwitterSquare, FaInstagram, FaLinkedin} from 'react-icons/fa'

const Footer = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleCredits = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            creditsActiveClass: 'is-active',
            })
          : this.setState({
            creditsActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <footer className="footer has-background-grey-dark has-text-white">

        <div className={`modal has-text-black ${this.state.creditsActiveClass}`}>
          <div className="modal-background" onClick={() => this.toggleCredits()}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Créditos</p>
              <button className="delete" aria-label="close" onClick={() => this.toggleCredits()}></button>
            </header>
            <section className="modal-card-body">
              <h1>Esta página presenta contenido diseñado por:</h1>
              <ul>
                <li><a href="https://www.freepik.com" target="_blank" rel="noopener">pikisuperstar / Freepik</a></li>
                <li><a href="https://www.freepik.com" target="_blank" rel="noopener">macrovector_official / Freepik</a></li>
                <li><a href="https://www.freepik.com" target="_blank" rel="noopener">vectorpocket / Freepik</a></li>
                <li><a href="https://www.freepik.com" target="_blank" rel="noopener">macrovector / Freepik</a></li>
                <li><a href="https://www.freepik.com" target="_blank" rel="noopener">freepik / Freepik</a></li>
                <li><a href="https://www.flaticon.com" target="_blank" rel="noopener">Freepik / Flaticon</a></li>
              </ul>
            </section>
          </div>
        </div>

        <div className="content has-text-centered has-background-grey-dark has-text-white">
          <div className="container has-background-grey-dark has-text-white">
            <div className="columns" style={{justifyContent: "space-around"}}>
              
              {/* <div className="column is-4"  >
                <h4 className="has-text-white">Dirección</h4>
              </div> */}
              <div className="column is-4" style={{textAlign: "left"}}>
                <h4 className="has-text-white">Ponte en contacto</h4>
                <hr></hr>
                <p style={{display: "flex", alignItems: "center"}}>
                  <MdPhoneAndroid style={{marginRight: "4px"}} />
                  <a className="has-text-white" href="tel:+34628824621">+34628824621</a>
                </p>
                <p style={{display: "flex", alignItems: "center"}}>
                  <MdMailOutline style={{marginRight: "4px"}} />
                  <a className="has-text-white" href="mailto:hola@modeliza.me">hola@modeliza.me</a>
                </p>
                <p style={{display: "flex", alignItems: "center"}}>
                  <MdLocationOn style={{marginRight: "4px"}} />
                  <a className="has-text-white" href="https://nuwiz.io">Nuwiz</a>
                </p>
                {/* <p style={{fontSize: "1.2em"}}>
                  <a className="has-text-white" href="#"><FaFacebookSquare/></a>
                  <a className="has-text-white" href="#"><FaTwitterSquare/></a>
                  <a className="has-text-white" href="#"><FaInstagram/></a>
                  <a className="has-text-white" href="#"><FaLinkedin/></a>
                </p> */}
              </div>

              <div className="column is-4" style={{textAlign: "left"}}>
                <h4 className="has-text-white">Modelizame</h4>
                <hr></hr>
                {/* Tagline */}
                <p>
                    <Link className="has-text-white" to="/legal/politica-de-privacidad">
                      Política de privacidad
                    </Link>
                </p>
                <p>
                    <Link className="has-text-white" to="/legal/politica-de-cookies">
                      Política de cookies
                    </Link>
                </p>
                <p>
                  <a className="has-text-white" onClick={() => this.toggleCredits()}>
                    Créditos
                  </a>
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
