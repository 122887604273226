import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'
import { MdPhoneAndroid, MdMailOutline, MdLibraryBooks } from 'react-icons/md'
import {FaCubes} from 'react-icons/fa'


const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      contactActive: false,
      contactActiveClass: ''
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  toggleContact = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        contactActive: !this.state.contactActive,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.contactActive
          ? this.setState({
              contactActiveClass: 'is-active',
            })
          : this.setState({
              contactActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent is-fixed-top has-shadow"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Modelizame"/>
              <p className="brand-name">Modelizame</p>
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end">
              <Link className="navbar-item" to="/demos/">
              <FaCubes style={{marginRight: "4px", color: "#2997FF"}} /> Demos
              </Link>
              <Link className="navbar-item" to="/blog">
              <MdLibraryBooks style={{marginRight: "4px", color: "#2997FF"}} /> Blog
              </Link>
              
              {/* <Link className="navbar-item" to="/soluciones">
                Soluciones
              </Link>
              <Link className="navbar-item" to="/casos-de-exito">
                Casos de éxito
              </Link>
              <Link className="navbar-item" to="/blog">
                Blog
              </Link> */}
              <div className="navbar-item">
                <a className="button" style={{backgroundColor: "#2997FF"}} onClick={() => this.toggleContact()}>
                  <strong className="has-text-white" >Contacto</strong>
                </a>
              </div>
              
            </div>
          </div>
          <div className={`modal has-text-black ${this.state.contactActiveClass}`}>
          <div className="modal-background" onClick={() => this.toggleContact()}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Ponte en contacto</p>
              <button className="delete " aria-label="close" onClick={() => this.toggleContact()}></button>
            </header>
            <section className="modal-card-body">
                <p className='mb-20 mt-20'>
                  <MdPhoneAndroid style={{marginRight: "4px"}} />
                  <a href="tel:+34628824621">+34628824621</a>
                </p>
                <p className='mb-20'>
                  <MdMailOutline style={{marginRight: "4px"}} />
                  <a href="mailto:hola@modeliza.me">hola@modeliza.me</a>
                </p>

            </section>
          </div>
        </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
